const classes = [
    {
        id: 1, value: 'Death Knight'
    },
    {
        id: 2, value: 'Demon Hunter'
    },
    {
        id: 3, value: 'Druid'
    },
    {
        id: 4, value: 'Evoker'
    },
    {
        id: 5, value: 'Hunter'
    },
    {
        id: 6, value: 'Mage'
    },
    {
        id: 7, value: 'Monk'
    },
    {
        id: 8, value: 'Paladin'
    },
    {
        id: 9, value: 'Priest'
    },
    {
        id: 10, value: 'Rogue'
    },
    {
        id: 11, value: 'Shaman'
    },
    {
        id: 12, value: 'Warlock'
    },
    {
        id: 13, value: 'Warrior'
    }
]

export default classes;